import {
  SingleRouteDispatchTypes,
  SINGLE_ROUTE_LOADING,
  SINGLE_ROUTE_FAILURE,
  SINGLE_ROUTE_SUCCESS,
  SingleRoute,
} from "../actions/singleRouteActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  routes?: SingleRoute;
  timestamp?: string;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: true,
  hasData: false,
};

const singleRouteReducer = (
  state: DefaultStateInterface = defaultState,
  action: SingleRouteDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case SINGLE_ROUTE_FAILURE:
      return {
        loading: false,
      };
    case SINGLE_ROUTE_LOADING:
      return {
        loading: true,
      };
    case SINGLE_ROUTE_SUCCESS:
      return {
        loading: false,
        routes: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default singleRouteReducer;
