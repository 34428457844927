import {
  FaultCodesDispatchTypes,
  FAULT_CODES_LOADING,
  FAULT_CODES_FAILURE,
  FAULT_CODES_SUCCESS,
  FaultCodes,
} from "../actions/historicalFaultCodesActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  faultCodes?: Array<FaultCodes>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const historicalFaultCodesReducer = (
  state: DefaultStateInterface = defaultState,
  action: FaultCodesDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case FAULT_CODES_FAILURE:
      return {
        loading: false,
      };
    case FAULT_CODES_LOADING:
      return {
        loading: true,
      };
    case FAULT_CODES_SUCCESS:
      return {
        loading: false,
        faultCodes: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof historicalFaultCodesReducer>;

export default historicalFaultCodesReducer;
