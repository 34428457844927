import {
  MapDateSelectionDispatchTypes,
  MAP_DATE_SELECTION_LOADING,
  MAP_DATE_SELECTION_SUCCESS,
} from "../actions/mapDateSelectionActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  mapDateSelection?: Date;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
  mapDateSelection: new Date(new Date()),
};

const mapDateSelectionReducer = (
  state: DefaultStateInterface = defaultState,
  action: MapDateSelectionDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case MAP_DATE_SELECTION_LOADING:
      return {
        loading: true,
      };
    case MAP_DATE_SELECTION_SUCCESS:
      return {
        loading: false,
        mapDateSelection: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default mapDateSelectionReducer;
