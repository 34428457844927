export const MAP_DATE_SELECTION_LOADING = "MAP_DATE_SELECTION_LOADING";
export const MAP_DATE_SELECTION_SUCCESS = "MAP_DATE_SELECTION_SUCCESS";

export type MapDateSelection = {
  mapDateSelection: Date;
};

export interface MapDateSelectionLoading {
  type: typeof MAP_DATE_SELECTION_LOADING;
}

export interface MapDateSelectionSuccess {
  type: typeof MAP_DATE_SELECTION_SUCCESS;
  payload: Date;
}

export type MapDateSelectionDispatchTypes =
  | MapDateSelectionLoading
  | MapDateSelectionSuccess;
