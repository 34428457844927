import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import historicalFaultCodesReducer from "./historicalFaultCodesReducer";
import popupReducer from "./popupReducer";
import vehicleSelectionReducer from "./vehicleSelectionReducer";
import vehicleListReducer from "./vehicleListReducer";
import mapDateSelectionReducer from "./mapDateSelectionReducer";
import settingsListReducer from "./settingsListReducer";
import singleRouteReducer from "./singleRouteReducer";
import metricsReducer from "./metricsReducer";
import lastOnlineReducer from "./lastOnlineReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  faultCodes: historicalFaultCodesReducer,
  popupView: popupReducer,
  vehicleSelection: vehicleSelectionReducer,
  vehicleList: vehicleListReducer,
  mapDate: mapDateSelectionReducer,
  settingsList: settingsListReducer,
  singleRoute: singleRouteReducer,
  lastOnline: lastOnlineReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
