import {
  SettingsListDispatchTypes,
  SETTINGS_LIST_LOADING,
  SETTINGS_LIST_FAILURE,
  SETTINGS_LIST_SUCCESS,
  Vehicles,
} from "../actions/settingsListActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  settingsList?: Array<Vehicles>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const settingsListReducer = (
  state: DefaultStateInterface = defaultState,
  action: SettingsListDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case SETTINGS_LIST_FAILURE:
      return {
        loading: false,
      };
    case SETTINGS_LIST_LOADING:
      return {
        loading: true,
      };
    case SETTINGS_LIST_SUCCESS:
      return {
        loading: false,
        settingsList: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof settingsListReducer>;

export default settingsListReducer;
