import { ReactElement, ReactNode } from "react";
export const FAULT_CODES_LOADING = "FAULT_CODES_LOADING";
export const FAULT_CODES_FAILURE = "FAULT_CODES_FAILURE";
export const FAULT_CODES_SUCCESS = "FAULT_CODES_SUCCESS";

export type FaultCodes = {
  dtc: string;
  timestamp: string;
};

export interface FaultCodesLoading {
  type: typeof FAULT_CODES_LOADING;
}

export interface FaultCodesFailure {
  type: typeof FAULT_CODES_FAILURE;
}

export interface FaultCodesSuccess {
  type: typeof FAULT_CODES_SUCCESS;
  payload: Array<FaultCodes>;
}

export type FaultCodesDispatchTypes =
  | FaultCodesLoading
  | FaultCodesFailure
  | FaultCodesSuccess;
