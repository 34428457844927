import { ReactNode } from "react";

export const LAST_ONLINE_LOADING = "LAST_ONLINE_LOADING";
export const LAST_ONLINE_FAILURE = "LAST_ONLINE_FAILURE";
export const LAST_ONLINE_SUCCESS = "LAST_ONLINE_SUCCESS";

export type LastOnline = {
  Latitude: number;
  Longitude: number;
  Date: string;
  VIN: string;
  DCM: string;
  AssetNumber: string;
};

export interface LastOnlineLoading {
  type: typeof LAST_ONLINE_LOADING;
}

export interface LastOnlineFailure {
  type: typeof LAST_ONLINE_FAILURE;
}

export interface LastOnlineSuccess {
  type: typeof LAST_ONLINE_SUCCESS;
  payload: Array<LastOnline>;
}

export type LastOnlineDispatchTypes =
  | LastOnlineLoading
  | LastOnlineFailure
  | LastOnlineSuccess;
