import { ReactNode } from "react";

export const SINGLE_ROUTE_LOADING = "SINGLE_ROUTE_LOADING";
export const SINGLE_ROUTE_FAILURE = "SINGLE_ROUTE_FAILURE";
export const SINGLE_ROUTE_SUCCESS = "SINGLE_ROUTE_SUCCESS";

export type SingleRoute = {
  id: string;
  route: Array<Coordinate>;
  timestamp: string;
  AirCompressorOn: boolean;
  StealthBatteryVolts: number;
  VanairBatteryVoltage: number;
  OEMBatteryVoltage: number;
  Odometer: number;
  MIL: boolean;
  FuelLevel: number;
  AssetNumber: string;
};

export type Coordinate = {
  lat: number;
  lng: number;
};

export interface SingleRouteLoading {
  type: typeof SINGLE_ROUTE_LOADING;
}

export interface SingleRouteFailure {
  type: typeof SINGLE_ROUTE_FAILURE;
}

export interface SingleRouteSuccess {
  type: typeof SINGLE_ROUTE_SUCCESS;
  payload: SingleRoute;
}

export type SingleRouteDispatchTypes =
  | SingleRouteLoading
  | SingleRouteFailure
  | SingleRouteSuccess;
